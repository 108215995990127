import React from 'react';
import Link from "next/link";
import { useMember } from '@/contexts/member/MemberContext';
import E_AISidebar from '@/components/sidebars/e_ai/E_AISidebar';
import { useCanvasProducts } from '@/contexts/editor/CanvasProductsContext';
import { ParentInterface } from '@/types';
import { CompanyInterface, useCompany } from '@/contexts/company/CompanyContext';
import { useModals } from '@/contexts/modals/ModalsContext';
import NotificationSidebar from './sidebars/notifications/NotificationSidebar';
import ProfileSidebar from '@/components/sidebars/profile/ProfileSidebar';
import { useQueue } from '@/contexts/queue/QueueContext';
import { useModels } from '@/contexts/models/ModelsContext';
import AnimatedUnderscore from './partials/common/AnimatedUnderscore';
import TextHelper from '@/helpers/TextHelper';
import SystemInfo from '@/helpers/SystemInfo';
import { __BG_ANIMATED_BLUE_GRADIENT__ } from '@/types/constants';
import LightningIcon from '@/components/svg/LightningIcon';
import HomeIcon from '@/components/svg/HomeIcon';
import { DateTime } from 'luxon';
import TaskIcon from './svg/TaskIcon';
import ConfigModal from './modals/config/ConfigModal';
import PrintIcon from './svg/PrintIcon';
import RowIcon from './svg/RowIcon';
import AddFileIcon from './svg/AddFileIcon';
import TemplatesIcon from './svg/TemplatesIcon';
import TagPriceIcon from './svg/TagIcon';
import { LiveTvOutlined } from '@mui/icons-material';
import { PresentationChartLineIcon, TagIcon } from '@heroicons/react/outline';
import OpenPackageIcon from './svg/OpenPackageIcon';
import PackageIcon from './svg/packageIcon';
import { usePathname } from 'next/navigation';

interface SidebarLinkProps {
	href: string;
	icon: React.ComponentType;
	title: string;
	count?: number | string;
	onClick?: () => void;
}

const Sidebar = () => {
	const { member } = useMember();
	const { company } = useCompany();
	const path = usePathname();
	const { showProductListModal, setShowProductListModal } = useCanvasProducts();
	const { showConfigModal, setShowConfigModal, showNotificationCenter, setNotificationCenter, showProfileModal, setShowProfileModal, setShowCampaignModal } = useModals();
	const { queueCount } = useQueue();
	const { countTotalTemplates } = useModels();

	const logo = company?.logos[0] as string | null;
	const epjLogoPath = "/images/brand/epj-e.png";

	const bannerMessage = (member.can('manage_company') && company) ?
		`Status: ${checkLicense(company)}` : `Status: Impressões restantes (${member.remaining})`;

	return (
		<React.Fragment>
			<E_AISidebar
				isOpen={showProductListModal}
				toggleSidebar={() =>
					setShowProductListModal(!showProductListModal)
				}
			/>
			<NotificationSidebar
				isOpen={showNotificationCenter}
				toggleSidebar={() =>
					setNotificationCenter(!showNotificationCenter)
				}
			/>
			<ProfileSidebar
				isOpen={showProfileModal}
				toggleSidebar={() => setShowProfileModal(!setShowProfileModal)}
			/>
			<ConfigModal
				isOpen={showConfigModal}
				onClose={() => setShowConfigModal(!showConfigModal)}
			/>

			<div className="h-screen flex justify-center">
				<div className="flex flex-col w-full max-w-xs min-w-[18rem] pt-2 bg-white border-r">
					<div className="h-screen min-w-full overflow-y-auto">
						<ul className="flex flex-col w-full">
							<li className="my-px">
								<div className="flex flex-row justify-start items-center p-4 pl-8 border-b">
									<img
										alt="Company logo"
										src={logo === null ? epjLogoPath : logo}
										className="mr-4 h-10 max-w-14"
									/>
									<div className="flex flex-col">
										<span className="text-zinc-700 font-bold cursor-default">
											{company?.name}
										</span>
										<span
											className="text-zinc-500 text-sm cursor-pointer hover:underline"
											onClick={() =>
												setShowProfileModal(true)
											}
										>
											{member.fullName}
										</span>
									</div>
								</div>
							</li>

							<SidebarLink
								href="/"
								icon={HomeIcon}
								title="Home"
							/>

							<Separator>Cartazeamento</Separator>

							<SidebarLink
								icon={() => <PrintIcon color="w-5 h-5" />}
								href="/products"
								title="Impressão"
							/>

							{company && company.useBatch && (<SidebarLink icon={() => (
								path.includes("batch")
									? <OpenPackageIcon className='w-5 h-5 stroke-zinc-500' />
									: <PackageIcon className='w-5 h-5 stroke-zinc-500' />
							)} href="/batch" title="Lote" />)}

							<SidebarLink
								icon={() => (
									<RowIcon color="w-5 h-5 stroke-zinc-500" />
								)}
								href="/fila"
								title="Fila"
								count={queueCount}
							/>

							{member.can("create_banner") && (
								<SidebarLink
									icon={() => <AddFileIcon color="w-5 h-5" />}
									href="#"
									title="Novo Modelo"
									onClick={() => setShowCampaignModal(true)}
								/>
							)}

							<SidebarLink
								icon={() => (
									<TemplatesIcon color="w-5 h-5 fill-white" />
								)}
								href="/models"
								title="Modelos"
								count={countTotalTemplates}
							/>

							{member.can("create_banner") && (
								<SidebarLink
									icon={() => (
										<TagPriceIcon color="w-5 h-5" />
									)}
									href="/campaigns"
									title="Campanhas"
								/>
							)}
							<Separator> </Separator>

							<Separator>Encartes de Oferta</Separator>
							<SidebarLink
								icon={() => (
									<TagIcon className="w-5 h-5 stroke-zinc-500" />
								)}
								href="/wip/Tabloide"
								title="Acessar Tabloide"
							/>
							<Separator> </Separator>

							<Separator>Gestão CRM</Separator>
							<SidebarLink
								icon={() => (
									<PresentationChartLineIcon className="w-5 h-5 stroke-zinc-500" />
								)}
								href="/wip/CRM"
								title="Acessar CRM"
							/>
							<Separator> </Separator>

							<Separator>Gestão TV's</Separator>
							<SidebarLink
								icon={() => (
									<LiveTvOutlined
										style={{
											strokeWidth: 0,
											stroke: "#71717a",
											width: "1.25rem",
											height: "1.25rem",
										}}
									/>
								)}
								href="/wip/TV"
								title="Acessar Sistema de TV"
							/>
							<Separator> </Separator>

							{
								// SystemInfo.isDev && member.admin() && (
								SystemInfo.isDev && (
									<React.Fragment>
										<Separator>Meus Grupos</Separator>

										<SidebarLink
											href="/tasks"
											icon={() => (
												<TaskIcon color="w-5 h-5 fill-zinc-500" />
											)}
											title="Tarefário"
										/>

										{/* <SidebarLink href="#" icon={CompanyIcon} title="Minha Empresa [WIP]" />

										<SidebarLink href="#" icon={MarketIcon} title="Minha Loja [WIP]" /> */}
									</React.Fragment>
								)
							}
							<Separator> </Separator>

							{(member.admin() || member.role === "manager") && (
								<React.Fragment>
									<Separator>Gerência</Separator>
									{member.admin() && (
										<SidebarLink
											href="/admin"
											icon={LightningIcon}
											title="Administração - EPJ"
										/>
									)}
									<SidebarLink
										href="/manager"
										icon={LightningIcon}
										title="Administração"
									/>
								</React.Fragment>
							)}
						</ul>
					</div>

					{company && (
						<div
							className={`${__BG_ANIMATED_BLUE_GRADIENT__} mb-20 m-auto py-4 px-8 rounded-lg w-fit cursor-default flex flex-col items-center justify-center`}
						>
							<span className="flex flex-col text-center w-full text-white overflow-hidden">
								{bannerMessage}
							</span>
						</div>
					)}
				</div>
			</div>
		</React.Fragment>
	);
};

export default Sidebar;

const Separator: React.FC<ParentInterface> = ({ children }) => {
	return (
		<li className="w-full pb-2 mt-2">
			<span className="flex font-semibold text-xs text-zinc-400 px-4 uppercase">
				{children}
			</span>
		</li>
	)
}

const TWLinkClasses = "flex flex-row items-center h-10 px-4 rounded-lg";

const Title: React.FC<ParentInterface> = ({ children }) => (
	<AnimatedUnderscore className="mx-3 w-full" textColor='text-zinc-600' lineColor='bg-app-primary'>
		{children}
	</AnimatedUnderscore>
);

export const SidebarLink: React.FC<SidebarLinkProps> = ({ href, icon: Icon, title, count, onClick = () => null }) => (
	<li className="my-px ml-4">
		<Link href={href} className={`${TWLinkClasses}`} onClick={onClick}>
			<span className="flex items-center justify-center text-lg text-zinc-500">
				<Icon />
			</span>
			<Title>{title}</Title>
			{
				count ? (
					<span className="flex items-center justify-center text-sm text-blue-600 font-semibold bg-blue-100 h-6 px-2 rounded-full ml-auto">
						{TextHelper.padNumber(count)}
					</span>
				) : <></>
			}
		</Link>
	</li>
);

function checkLicense(company: CompanyInterface | null): string {
	if (!company) {
		return 'Nenhuma licença disponível';
	}

	const currentDate = DateTime.now();
	const licenseExpirationDate = DateTime.fromISO(company.licenseExpiration)
		.set({ hour: 23, minute: 59, second: 59 });

	if (licenseExpirationDate <= currentDate) {
		return 'Licença expirada';
	}
	else if (licenseExpirationDate.hasSame(currentDate, 'day')) {
		return 'Vence hoje';
	}
	return company.licenseExpirationRemainingLabel
		? `${company.licenseExpirationRemainingLabel} restantes`
		: 'Licença válida (sem informações adicionais)';
}
